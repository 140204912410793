/* eslint-disable class-methods-use-this */
export default class QueryBuilder {
  constructor(options) {
    this._options = options
    this._fieldType = options.fieldType
  }

  filterTypes = ['is', 'is_not', 'match', 'match_not', 'exists', 'exists_not']

  // TODO: make this a function. (usecase: selects should start with their first option)
  initialQuery = ''

  showSearchField(filter) {
    return !['exists', 'exists_not'].includes(filter.type)
  }

  filterToQueryFragment(filter) {
    let must
    let query
    switch (filter.type) {
      case 'is':
        must = true
        query = { term: { [`${filter.field}.original`]: filter.query } }
        break
      case 'is_not':
        must = false
        query = { term: { [`${filter.field}.original`]: filter.query } }
        break
      case 'match':
        must = true
        query = {
          match: { [filter.field]: { query: filter.query, fuzziness: 'AUTO' } }
        }
        break
      case 'match_not':
        must = false
        query = {
          match: { [filter.field]: { query: filter.query, fuzziness: 'AUTO' } }
        }
        break
      case 'exists':
        must = true
        query = { exists: { field: filter.field } }
        break
      case 'exists_not':
        must = false
        query = { exists: { field: filter.field } }
        break
      case 'greater_than':
        must = true
        query = { range: { [filter.field]: { gt: filter.query } } }
        break
      case 'less_than':
        must = true
        query = { range: { [filter.field]: { lt: filter.query } } }
        break
      default:
        return null
    }

    return {
      bool: {
        [must ? 'must' : 'must_not']: query
      }
    }
  }
}
