/* eslint-disable class-methods-use-this */
import QueryBuilder from 'utils/fieldTypes/QueryBuilder'

export default class CheckboxQueryBuilder extends QueryBuilder {
  filterTypes = ['is']

  initialQuery = true

  filterToQueryFragment(filter) {
    return { term: { [filter.field]: filter.query } }
  }
}
