import { mergeDeepRight } from 'ramda'
import DefaultQueryBuilder from 'utils/fieldTypes/QueryBuilder'

const DEFAULT_CONFIG = {
  hasChildren: false,
  hasOptions: false,
  hasData: false
}

function requireQueryBuilder(fieldTypeName) {
  try {
    return require(`./${fieldTypeName}/QueryBuilder.js`).default
  } catch (e) {
    return DefaultQueryBuilder
  }
}

// KEEP: Use this to debug the regex
// console.log(
//   require
//     .context('.', true, /.*/im)
//     .keys()
//     .join('\n')
// )

const req = require.context(
  '.',
  true,
  // https://regex101.com/r/pVrWB7/1
  /^\.\/([^/\r\n]*)\/index\.js$/im
)
const fieldTypes = req.keys().reduce((acc, key) => {
  const [_, fieldTypeName] = key.match(/\.\/(.*)\/index\.js$/im)
  const _fieldTypeConfig = req(key)
  const fieldTypeConfig = mergeDeepRight(DEFAULT_CONFIG, _fieldTypeConfig)
  const QueryBuilder = requireQueryBuilder(fieldTypeName)
  const queryBuilder = new QueryBuilder({ fieldType: fieldTypeConfig })
  return {
    ...acc,
    [fieldTypeName]: {
      ...fieldTypeConfig,
      queryBuilder,
      name: fieldTypeName
    }
  }
}, {})

module.exports = fieldTypes
