import QueryBuilder from 'utils/fieldTypes/QueryBuilder'
import { getTime } from 'date-fns'

export default class DateQueryBuilder extends QueryBuilder {
  filterTypes = ['greater_than', 'less_than', 'exists', 'exists_not']

  initialQuery = getTime(new Date())

  filterToQueryFragment(filter) {
    const superQueryFragment = super.filterToQueryFragment({
      ...filter,
      query:
        filter.query instanceof Date ? filter.query.getTime() : filter.query
    })
    if (superQueryFragment) return superQueryFragment
    return null
  }
}
